import axios from '@axios'
import common from '@/libs/common'

export default function configService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchBulletins = (data, callback) => {
    axios
      .get(`${API_URL}/Boletines`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchBulletin = (boletinId, callback) => {
    axios
      .get(`${API_URL}/Boletines/${boletinId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchCustomersBulletins = (data, callback) => {
    axios
      .get(`${API_URL}/Boletines/Clientes`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createBulletin = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Boletines`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const updateBulletin = (data, callback) => {
    axios
      .put(`${API_URL}/Boletines/${data.boletinId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const deleteBulletin = (boletinId, callback) => {
    axios
      .delete(`${API_URL}/Boletines/${boletinId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchBulletins,
    fetchBulletin,
    createBulletin,
    updateBulletin,
    deleteBulletin,
    fetchCustomersBulletins,
  }
}
